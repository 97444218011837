import { render, staticRenderFns } from "./StepMain.vue?vue&type=template&id=cd08569e&scoped=true&"
import script from "./StepMain.vue?vue&type=script&lang=js&"
export * from "./StepMain.vue?vue&type=script&lang=js&"
import style0 from "./StepMain.vue?vue&type=style&index=0&id=cd08569e&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cd08569e",
  null
  
)

export default component.exports