<template>
    <div>
        <div class="page-title">超级管理员信息</div>

        <pay-box title="超级管理员信息" sub-title="超级管理员将接收开户信息及完成签约，并可进行商户号的日常重要管理及资金操作。">

            <div v-if="ready">
                <div class="pay-apply-item">

                    <div class="pay-apply-item-title">超管身份</div>

                    <div class="pay-apply-item-content">
                        法定代表人/经营者
                    </div>

                </div>


                <div class="pay-apply-item">

                    <div class="pay-apply-item-title">超管姓名</div>

                    <div class="pay-apply-item-content">
                        {{ params.contact_info.contact_name }}
                    </div>

                </div>

                <div class="pay-apply-item">

                    <div class="pay-apply-item-title">手机号码</div>

                    <div class="pay-apply-item-content">
                        <at-input v-model="params.contact_info.mobile_phone" unit="+86" class="pay-apply-input"/>
                    </div>

                </div>

                <div class="pay-apply-item">

                    <div class="pay-apply-item-title">邮箱</div>

                    <div class="pay-apply-item-content">
                        <at-input v-model="params.contact_info.contact_email" class="pay-apply-input"/>
                    </div>

                </div>

                <div class="pay-apply-tips">用于接收微信支付的开户邮件及日常业务通知</div>
            </div>
            <div v-else>
                <p>请先完成主体资料填写</p>
                <a @click="changeStep(3)">立即前往</a>
            </div>

        </pay-box>


        <div class="pay-apply-btns">
            <button @click="saveChange(2)" class="btn btn-white">返回</button>
            <button @click="saveChange(2)" class="btn">下一步</button>
        </div>

    </div>
</template>

<script>
    import PayBox from "./PayBox";
    import AtInput from "../../../../components/AtInput";
    import api from "../../../../repo/api";

    export default {
        name: "Step6",
        components: {AtInput, PayBox},
        props: {
            apply: Object
        },
        data() {
            return {
                ready: false,
                params: {
                    contact_info: {
                        contact_name: '',
                        mobile_phone: '',
                        contact_email: '',
                    }
                }
            }
        },
        mounted() {

            let info = this.apply['subject_info']['identity_info']

            if (!info) return

            info = info['id_card_info']

            if (!info) return

            this.params.contact_info = this.apply['contact_info'] || {}

            this.params.contact_info.contact_name = info['id_card_name']

            this.ready = true


        },
        methods: {
            changeStep(step) {
                this.$emit('change', step)
            },
            saveChange(step) {

                if (!this.ready) return this.changeStep(step)

                this.$loading.show()

                api.post('/pay-apply/save-contact-info', this.params, (data) => {

                    this.$loading.hide()

                    if (data.code !== 0) this.$message.data(data)

                    if (data.code === 0) {
                        this.$emit('change', step, data.data)
                    }

                })
            }
        }
    }
</script>

<style scoped>

</style>