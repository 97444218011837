<template>
    <div class="apply-list">

        <div v-if="apply">
            <div v-show=" step === 1 ">
                <step1 :apply="apply" @change="gotoStep"/>
            </div>

            <div v-if="step === 2">
                <step-main :code="code" :time="time" :apply="apply" @change="gotoStep"/>
            </div>

            <div v-if="step === 3">
                <step3 :apply="apply" @change="gotoStep"/>
            </div>

            <div v-if="step === 4">
                <step4 :apply="apply" @change="gotoStep"/>
            </div>

            <div v-if="step === 5">
                <step5 :apply="apply" @change="gotoStep"/>
            </div>

            <div v-if="step === 6">
                <step6 :apply="apply" @change="gotoStep"/>
            </div>

            <div v-if="step === 7">
                <step7 :code="code" :apply="apply" @change="gotoStep"/>
            </div>
        </div>


    </div>
</template>

<script>
import Step1 from "./Step/Step1";
import StepMain from "./Step/StepMain";
import Step3 from "./Step/Step3";
import Step4 from "./Step/Step4";
import Step5 from "./Step/Step5";
import Step6 from "./Step/Step6";
import api from "@/repo/api";
import Step7 from "./Step/Step7";

export default {
    name: "Index",
    components: {Step7, Step6, Step5, Step4, Step3, StepMain, Step1},
    props:{
        customerType:String,
    },
    data() {
        return {
            apply: null,
            code: '',
            time: '',
            step: 0,
            tmFlag: '',
        }
    },
    mounted() {
        this.getApply()
    },
    destroyed() {
        clearTimeout(this.tmFlag)
    },
    methods: {
        getApply(repeat) {

            if (!repeat) this.$loading.show()

            api.get('/pay-apply/apply', (data) => {

                this.$loading.hide()

                this.loadApply(data.data)

                if (data.data.applyment_state === 'APPLYMENT_STATE_FINISHED') return;

                if (this.step === 7) this.tmFlag = setTimeout(() => {
                    this.getApply(true)
                }, 5000)
            })

        },
        loadApply(data) {

            let apply = data.info || {}

            this.apply = apply

            if (apply.bank_account_info) {

                if (apply.bank_account_info.account_bank !== '其他银行') {
                    delete apply.bank_account_info.bank_name
                }
            }

            if (data.applyment_id) {
                this.step = 7
            } else {
                this.step = apply && apply['subject_info'] ? 2 : 1
            }

            this.code = data.business_code
            this.time = data.created_at

        },
        gotoStep(step, apply) {
            window.scrollTo(0, 50)
            this.step = step
            if (apply) this.loadApply(apply)
        },

    }
}
</script>

<style lang="less" scoped>

.apply-list {
    max-width: 1000px;
}

</style>