<template>
    <div>
        <div class="page-title">申请提交</div>

        <div class="res-body">
            <div class="res-content">

                <template
                        v-if=" reviewInfo.applyment_state === 'APPLYMENT_STATE_TO_BE_SIGNED' ||  reviewInfo.applyment_state === 'APPLYMENT_STATE_AUDITING' ||  reviewInfo.applyment_state === 'APPLYMENT_STATE_TO_BE_CONFIRMED' "
                >
                    <div class="res-content-block">
                        <div class="success-icon"></div>
                        <div class="success-title">申请提交成功</div>
                        <div class="success-code">申请单号：{{ code }}</div>
                        <div class="qr-tips">超级管理员（{{ reviewInfo.id_name }}）请用微信扫码</div>
                        <div class="qrcode">
                            <img style="width: 100%" v-if="reviewInfo" :src=" reviewInfo.qrcode " alt="">
                        </div>

                        <div class="att">关注「微信支付商家助手」公众号</div>
                    </div>
                </template>

                <template
                        v-if=" reviewInfo.applyment_state === 'APPLYMENT_STATE_EDITTING' ||  reviewInfo.applyment_state === 'APPLYMENT_STATE_REJECTED' "
                >
                    <div class="res-content-block">
                        <div class="error-icon"></div>

                        <div class="error-title">提交申请发生错误导致 请尝试重新提交</div>
                        <div class="success-code">申请单号：{{ code }}</div>

                    </div>

                </template>

                <template
                        v-if=" reviewInfo.applyment_state === 'APPLYMENT_STATE_CANCELED' "
                >
                    <div class="res-content-block">
                        <div class="error-icon"></div>

                        <div class="error-title">申请单已被撤销 请尝试重新提交</div>
                        <div class="success-code">申请单号：{{ code }}</div>

                    </div>

                </template>

                <template
                        v-if=" reviewInfo.applyment_state === 'APPLYMENT_STATE_SIGNING' "
                >
                    <div class="res-content-block">
                        <div class="pr-icon"></div>

                        <div class="error-title">申请单正在审核中</div>
                        <div class="success-code">申请单号：{{ code }}</div>

                    </div>

                </template>

                <template
                        v-if="reviewInfo.applyment_state === 'APPLYMENT_STATE_FINISHED' "
                >
                    <div class="pr-icon"></div>

                    <div class="error-title">审核已经通过</div>
                    <div class="success-code">申请单号：{{ code }}</div>

                </template>

                <template v-else>
                    <div class="att-tips">
                        公众号将自动发送签约消息；超级管理员需点击消息，根据指引完成核对联系信息、账户验证、签约等操作。
                    </div>
                    <div class="att-tips att-tips-2">如资料填写错误或收到官方驳回请重新申请</div>
                    <div class="rb">
                        <button @click="reject" class="rb-btn">撤销重新申请</button>
                    </div>
                </template>

                <div class="wx-log"></div>
            </div>
        </div>
    </div>
</template>

<script>
import api from "../../../../repo/api";

export default {
    name: "Step7",
    props: {
        apply: Object,
        code: String
    },
    data() {
        return {
            reviewInfo: {}
        }
    },
    mounted() {
        this.getStatus()
    },
    methods: {
        getStatus() {
            this.$loading.show()

            api.get('/pay-apply/apply-status', (data) => {

                this.$loading.hide()

                this.reviewInfo = data.data

                if (this.reviewInfo.applyment_state === 'APPLYMENT_STATE_FINISHED') {
                    if (this.$route.path === '/ast/reg') {
                        location.reload()
                    }
                }
            })
        },
        reject() {
            this.$confirm({
                title: '确定要撤销申请吗？',
                onOk: () => {
                    this.$loading.show()

                    api.post('/pay-apply/reject', (data) => {

                        this.$loading.hide()

                        if (data.code === 0) {
                            this.$message.success('申请已经撤销');

                            setTimeout(() => {
                                window.location.reload()
                            }, 2000)

                        } else {
                            this.$message.error('撤销失败');
                        }
                    })


                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.res-body {
    min-width: 700px;
    height: 616px;
    background-image: url("/assets/images/pay-apply-bg.png");
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
}

.res-content {
    width: 280px;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    flex-direction: column;
    color: #FFFFFF;
    text-align: center;
    padding: 0 20px;
}


.success-icon {
    width: 48px;
    height: 48px;
    background-image: url("/assets/icons/apply-success.png");
    background-size: cover;
    margin: 24px auto 0;
}

.error-icon, .pr-icon {
    margin: 178px auto 0;
    width: 40px;
    height: 40px;
    background-image: url("/assets/icons/pay-apply-error.png");
    background-size: cover;
}

.pr-icon {
    background-image: url("/assets/icons/pay-apply-pr.png");
}

.error-title {
    width: 160px;
    height: 44px;
    font-size: 16px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 22px;
    margin: 16px auto 0;
}

.success-title {
    margin-top: 12px;
    height: 16px;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
}

.success-code {
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    margin-top: 8px;
}

.qr-tips {
    margin-top: 32px;
    height: 14px;
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 14px;
}

.qrcode {
    width: 140px;
    height: 140px;
    background: #FFFFFF;
    padding: 3px;
    margin: 24px auto 0;
}

.att {
    margin-top: 24px;
    height: 14px;
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 14px;
}

.att-tips {
    font-size: 12px;
    font-weight: 400;
    line-height: 19px;
    margin-top: 20px;
    text-align: left;
}

.att-tips-2 {
    margin-top: 6px;
}

.wx-log {
    margin: 28px auto 0;
    width: 84px;
    height: 21px;
    background-image: url("/assets/icons/wx-pay-logo.png");
    background-size: cover;
}

.res-content-block {
    height: 388px;
    overflow: hidden;
    border-bottom: 1px dashed #FFF;
}

.rb-btn {
    width: 240px;
    height: 37px;
    background: #FFFFFF;
    outline: none;
    font-size: 14px;
    font-weight: 500;
    color: #333333;
    line-height: 20px;
    border: 0;
}

.rb {
    margin-top: 23px;
}

</style>